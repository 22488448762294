import { useEffect, useRef, useState } from 'react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { ImageGallery } from './components/ImageGallery';
import { MapCurrentLocal } from './components/MapCurrentLocal';
import { AmenitiesCondominium, CompleteAmenities, SessionItens } from './components/SessionItens';
import * as S from './styles';
import fetchAxios, { fetchAxiosAdmin } from 'services/http';
import { useParams, parsePath, useLocation, useNavigate } from 'react-router-dom';
import { AmenitiesProps } from './components/Amenities';
import { useHistoryStack } from 'hooks/useHistory';
import { useScreenWidth } from 'utils/useScreenWidth';
import { HeaderMobile } from 'components/HeaderMobile';
import MobileGallery from './components/MobileGallery';
import { MobileNavigation } from 'components/MobileNavigation';
import { LoadingMobile } from 'components/LoadingMobile';
import { WhatsappButton } from 'components/WhatsappButton';
import { ReserveDetails } from 'pages/Payment/components/PaymentCard/types';
import { Helmet } from 'react-helmet-async';
import { ProductCard } from 'components/ProductCard';
import { Button } from 'components/Button';

export const Product = () => {
  const { id: buildingId } = useParams<{ id: string | undefined }>();

  const { pushToHistory } = useHistoryStack();
  const location = useLocation();

  useEffect(() => {
    pushToHistory(location.pathname);
  }, [location.pathname]);

  const accommodationId = parsePath(window.location.pathname)?.pathname?.split('/')[2];
  const [accommodationDescription, setAccommodationDescription] = useState<string>('');
  const [buildingDescription, setBuildingDescription] = useState<string>('');
  const [accommodationData, setAccommodationData] = useState<any>([]);
  const [garage, setGarage] = useState(false);
  const [accommodationUnavailable, setAccommodationUnavailable] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const link = window.location.href;

  const [metaData, setMetaData] = useState({
    title: "Yogha",
    description: "Descrição do seu conteúdo.",
    image: "URL da imagem do seu conteúdo",
    url: `Endereço da página`
  });

  const [completeAmenities, setCompleteAmenities] = useState<CompleteAmenities[]>([]);
  const [amenitiesCondominum, setAmenitiesCondominum] = useState<AmenitiesCondominium[]>([]);
  const [houseRules, setHouseRules] = useState<string>('');
  const [reserveDetails, setReserveDetails] = useState<ReserveDetails>({
    accommodationName: '',
    address: {
      city: '',
      area: '',
      stateCode: '',
      street: ''
    },
    id: '',
    mainImage: ''
  });

  const breakpoint = useScreenWidth();

  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [minimumStay, setMinimumStay] = useState<number>(0);
  const [address, setAddress] = useState<any>({});

  const [basicInfo, setBasicInfo] = useState<AmenitiesProps | undefined>({
    baths: 0,
    beds: 0,
    maxGuests: 0,
    rooms: 0,
    pet: false,
    garage: false,
    meters: 0
  });

  const [images, setImages] = useState<string[]>([]);

  const getAccommodation = async (
    idAccomodation: string | number | undefined,
    idBuilding: string | number | undefined
  ) => {
    setIsLoading(true);
    try {
      const { data } = await fetchAxios.post(`/accommodations/${idAccomodation}`, {
        idBuilding: idBuilding
      });
      const { data: dataStay } = data;
      setHouseRules(dataStay._mshouserules.pt_BR);
      setAccommodationDescription(dataStay._msdesc.pt_BR);
      setBuildingDescription(data.description);
      setAccommodationData(dataStay);
      setReserveDetails({
        accommodationName: dataStay._mstitle.pt_BR,
        address: {
          city: dataStay.address.city,
          area: dataStay.address.region,
          stateCode: dataStay.address.countryCode,
          street: dataStay.address.street
        },
        id: dataStay._id,
        mainImage: dataStay._t_imagesMeta[0].url
      });

      const codeAccommodation = dataStay._mstitle.pt_BR.slice(-6)

      setMetaData({
        title: "Yogha " + dataStay._mstitle.pt_BR || "Yogha",
        description: dataStay._msdesc.pt_BR || "Descrição do seu conteúdo.",
        image: dataStay._t_imagesMeta[0].url || "URL da imagem do seu conteúdo",
        url: `${link}/h/${codeAccommodation}`
      })

      // setImages(extractUrls(dataStay._t_imagesMeta));
      setLatitude(dataStay.latLng._f_lat);
      setLongitude(dataStay.latLng._f_lng);
      setAddress(dataStay.address);
      setBasicInfo({
        maxGuests: dataStay._i_maxGuests,
        beds: dataStay._i_beds,
        baths: dataStay._f_bathrooms,
        rooms: dataStay._i_rooms,
        meters: data.metreage
      });
      setMinimumStay(data.rulles[0].min_stay ? data.rulles[0].min_stay : 1);
      data.completeAmenities.map((item: any) => {
        if (item.amenitie_id === '2M6fwAAWs9708iuSv4AhOyOV') {
          setBasicInfo({ pet: true });
        }
        if (item.amenitie_id === '5aded91022261d4730423b2a' || item.amenitie_id === '58931b3fe1875b6163592c6f') {

          setGarage(true);
        }
      });
      setCompleteAmenities(data.completeAmenities);
      setAmenitiesCondominum(data.buildingsInfos);
      setIsLoading(false);
    } catch (error) {
      const AxiosError = error as any;
      const { data } = AxiosError.response;
      if (data.message === "Não encontrada" && data.code === 400) {
        window.location.href = '/';
      }
      setIsLoading(false);
    }
  };

  const getImagesGallery = async (idAccomodation: string | number | undefined) => {
    try {
      if (breakpoint) {
        const linkImage = 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/800x600/filters:quality(70)/'
        const { data } = await fetchAxiosAdmin.get(`avantio/get-image-links/${idAccomodation}/all`,);
        const mappedImages = data.map((item: any) => {
          return linkImage + item.image_path
        })
        setImages(mappedImages)
      } else {
        const linkImage = 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/800x600/filters:quality(65)/'
        const { data } = await fetchAxiosAdmin.get(`avantio/get-image-links/${idAccomodation}/5`,);
        const mappedImages = data.map((item: any) => {
          return linkImage + item.image_path
        })
        setImages(mappedImages)
      }
    } catch (error) {
      console.error(error);
      throw new Error('error')
    }
  }

  useEffect(() => {
    if (accommodationData.length === 0) {
      getAccommodation(accommodationId, buildingId);
    }
    getImagesGallery(accommodationId)
  }, [buildingId, accommodationId]);

  const [cardBottom, setCardBottom] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [visiblePrice, setVisiblePrice] = useState(false);
  const scrollThreshold = 500;

  const SessionDiv = useRef<HTMLDivElement>(null);
  const locationRef = useRef<HTMLDivElement>(null);
  const imagesRef = useRef<HTMLDivElement>(null);

  document.title = `${reserveDetails.accommodationName}`;

  const navigate = useNavigate();

  useEffect(() => {
    const scrollableElement = SessionDiv;

    const handleScroll = () => {
      const currentScrollY = scrollableElement.current?.scrollTop;
      if (currentScrollY !== undefined) {
        if (currentScrollY < 600) {
          setHeaderVisible(false);
        }
        if (currentScrollY > 2410) {
          setVisiblePrice(true);
        } else if (currentScrollY < 2400) {
          setVisiblePrice(false);
        }
        if (currentScrollY > scrollThreshold && currentScrollY < 1740) {
          setIsTabVisible(true);
        } else if (currentScrollY > scrollThreshold && currentScrollY > 1740) {
          setCardBottom(true);
          setIsTabVisible(false);
        } else if (currentScrollY < scrollThreshold) {
          setIsTabVisible(false);
          setCardBottom(false);
          setHeaderVisible(true);
        }
      }
    };

    scrollableElement.current?.addEventListener('scroll', handleScroll);

    return () => {
      scrollableElement.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Helmet async prioritizeSeoTags>
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:image" content={metaData.image} />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image" content={metaData.image} />
        <link rel="stylesheet" href="" />
      </Helmet>
      {accommodationData !== null ? (
        <S.Container ref={SessionDiv}>
          {headerVisible && !breakpoint ? <Header /> : <HeaderMobile />}

          {breakpoint && isLoading ? (
            <LoadingMobile />
          ) : (
            <S.Content>
              {breakpoint ? (
                <MobileGallery
                  isLoading={isLoading}
                  previwImages={images}
                  accommodationId={accommodationId}
                  city={accommodationData?.address?.city}
                  name={accommodationData?._mstitle?.pt_BR}
                  imagesRef={imagesRef} />
              ) : (
                <ImageGallery
                  isLoading={isLoading}
                  previwImages={images}
                  accommodationId={accommodationId}
                  city={accommodationData?.address?.city}
                  name={accommodationData?._mstitle?.pt_BR}
                  imagesRef={imagesRef}
                />
              )}
              <SessionItens
                isLoading={isLoading}
                accommodationDescription={accommodationDescription}
                buildingDescription={buildingDescription}
                houseRules={houseRules}
                reserveDetails={reserveDetails}
                amenitiesCondominium={amenitiesCondominum}
                completeAmenities={completeAmenities}
                accomodationId={accommodationId}
                basicInfo={basicInfo}
                imagesRef={imagesRef}
                locationRef={locationRef}
                priceTab={visiblePrice}
                visible={!headerVisible}
                scrollContainerRef={SessionDiv}
                garage={garage}
                minimumStay={minimumStay}
              />
              <MapCurrentLocal latitude={latitude} longitude={longitude} locationRef={locationRef} address={address} />
            </S.Content>
          )}
          <Footer />
          <WhatsappButton />
          <MobileNavigation />
        </S.Container>
      ) : null}
    </>
  );
};
