
import * as S from './styles'
import ReactDOM from 'react-dom'
import { ReactNode } from 'react';

type ModalProps = {
    children: ReactNode
}

export const Modal = ({ children }: ModalProps) => {
    const modalRoot = document.getElementById('modal-root');

    return modalRoot
        ? ReactDOM.createPortal(
            (
                <S.Container>
                    {children}
                </S.Container>
            ),
            modalRoot
        )
        : null;
};