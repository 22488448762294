import styled from "styled-components";
import { UserDropdownMenuProps } from ".";

export const Container = styled.div<UserDropdownMenuProps>`
    `;

export const Overlay = styled.div<UserDropdownMenuProps>`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
    `;

export const CloseButton = styled.button<UserDropdownMenuProps>`
    `;

export const Content = styled.div<UserDropdownMenuProps>`
    `;

export const Modal = styled.div`
`
