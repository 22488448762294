import { useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { HeaderAlt } from '../../components/HeaderAlt';
import useAlert from '../../components/Notification';
import fetchAxios from 'services/http';
import { useUserProvider } from 'hooks/userContext';
import jwtDecode from 'jwt-decode';
import { Loading } from 'pages/Payment/components/Loading';
import { useAnyProvider } from 'hooks/Context';
import { isAfter } from 'date-fns';
import { WhatsappButton } from 'components/WhatsappButton';
import { useSessionStorage } from 'context/sessionStorage';

export const Login = () => {


  const [reserveData, setReserveData] = useState<any>()

  useEffect(() => {
    const reserveDataStorage = getFromSessionStorage('reserver')
    if (reserveDataStorage) {
      setReserveData(reserveDataStorage)
    }
  }, [])


  const { saveToSessionStorage, getFromSessionStorage } = useSessionStorage()

  const { setUserName, setToken, setCustomer, setUserEmail, setUserPhone, setUserDocument, setUserAvatar, setUserCustomerId, setUserSurname, setAccessToken, setStatusAccount, setCardPaymentBlock } = useUserProvider()

  const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')

  // useEffect(() => {
  //   if (token !== null && isAuth) {
  //     // navigate(-1)
  //   }
  // }, [isAuth, navigate]);

  const [password, setPassword] = useState<string>('')
  const [passwordTextError, setPasswordTextError] = useState<string>('')
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [passwordErrorLink, setPasswordErrorLink] = useState<string>('')
  const [passwordLinkTextError, setPasswordLinkTextError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [emailTextError, setEmailTextError] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(false)

  async function handleLogin() {

    if (email === '') {
      setEmailError(true)
      setEmailTextError('Digite o seu e-mail.')
      return
    }
    if (!email.includes('@')) {
      setEmailError(true)
      setEmailTextError('Esse e-mail é inválido. Tente usar assim: exemplo@email.com')
      return
    }
    if (password === '') {
      setPasswordError(true)
      setPasswordTextError('Digite a sua senha.')
      return
    }
    setIsLoading(true)
    try {
      const res = await fetchAxios.post('users/login',
        {
          email: email,
          password: password
        }
      );
      if (res.data.code !== 400 && res.data.name !== '') {
        setUserName(res.data.name)
        setStatusAccount(res.data.person_type)
        setToken(res.data.token)
        setCustomer(res.data.asaas_customer_id)
        setUserPhone(res.data.mobile_phone)
        setUserEmail(res.data.email)
        setUserDocument(res.data.identity_document === null || res.data.identity_document === 'null' ? '' : res.data.identity_document)
        setUserAvatar(res.data.site_avatar !== null ? res.data.site_avatar : '')
        setUserSurname(res.data.surname)
        setUserCustomerId(res.data.id)
        setAccessToken(res.data.acess_token)
        handleSaveLocalStorange(res.data)
        setCardPaymentBlock(String(res.data.isBlock))
        if (!!reserveData) {
          saveToSessionStorage('reserver', { ...reserveData, user: { name: res.data.name, email: res.data.email, phone: res.data.mobile_phone, dni: res.data.identity_document === null || res.data.identity_document === 'null' ? '' : res.data.identity_document, surname: res.data.surname, customer_id: res.data.id } })
          navigate('/payment')
        } else {
          navigate('/')
        }
        setIsLoading(false)
      }
      if (res.data.code === 400 && res.data.message === "Usuario não encontrado") {
        setPasswordError(false)
        setPasswordTextError('')
        setPasswordErrorLink('/forgotpassword')
        setPasswordLinkTextError('')
        showAlert({ active: true, title: 'Desculpe, não encontramos uma conta com esse endereço de email. Tente novamente ou crie uma nova conta.', type: 'ERROR' })
      }
      if (res.data.code === 400 && res.data.message === "Usuario ou senha incorreta") {
        setPasswordError(true)
        setPasswordTextError('A senha que você inseriu está incorreta. Tente novamente ou')
        setPasswordErrorLink('/forgotpassword')
        setPasswordLinkTextError('clique aqui para redefinir a senha')
      }
    } catch (error) {
      setIsLoading(false)
    }
    finally {
      setIsLoading(false)
    }
  }


  const handleSaveLocalStorange = (data: any) => {
    localStorage.setItem('token', data.token);
    localStorage.setItem('customer', data.asaas_customer_id);
    localStorage.setItem('userName', data.name);
    localStorage.setItem('userAvatar', data.site_avatar !== null ? data.site_avatar : '');
    localStorage.setItem('userEmail', data.email);
    localStorage.setItem('userPhone', data.mobile_phone);
    localStorage.setItem('userDocument', data.document);
    localStorage.setItem('userSurname', data.surname);
    localStorage.setItem('userCustomerId', data.id);
    localStorage.setItem('userAccessToken', data.acess_token);
    localStorage.setItem('userStatusAccount', data.person_type);
    const decodedToken: any = jwtDecode(data.token);
    const expirationTimeInSeconds = decodedToken.exp;
    const expirationDate = new Date(expirationTimeInSeconds * 1000); // Converte para milissegundos
    localStorage.setItem('token', data.token);
    localStorage.setItem('expirationTime', expirationDate.toString());
    localStorage.setItem('cardPaymentStatus', String(data.isBlock));
  }

  return (
    <S.Container>
      <HeaderAlt />
      <S.Content>
        <S.TitleContainer>
          <S.Title>Inicie a sessão</S.Title>
        </S.TitleContainer>
        {/* <S.SocialContainer>
          <S.RegisterButton>
            <img src={googleIcon} alt="" />
            <p>Continue com Google</p>
          </S.RegisterButton>
          <S.RegisterButton>
            <img src={facebookIcon} alt="" />
            <p>Continue com Facebook</p>
          </S.RegisterButton>
        </S.SocialContainer>
        <S.Separator>
          <p>ou</p>
        </S.Separator> */}
        <S.InputContainer>
          <Input htmlFor='email' width='100%' onChange={e => setEmail(e.currentTarget.value)} placeholder='Email' title='Email' type='text' errorMessages={emailError} errorText={emailTextError} value={email} />
          <Input showPassword htmlFor='password' width='100%' onChange={e => setPassword(e.currentTarget.value)} placeholder='Senha' title='Senha' type='password' value={password} errorMessages={passwordError} errorLinkText={passwordLinkTextError} onBlur={() => {
            setPasswordError(false)
            setPasswordTextError('')
          }} errorText={passwordTextError} linkError={passwordErrorLink} />
        </S.InputContainer>
        <S.ForgotPassword>
          <Button disabled={false} icon='' onClickButton={() => navigate('/forgotpassword')} size='small' title='Esqueceu sua senha ?' type='secondary' widthSize={157} />
        </S.ForgotPassword>
        <Button type='primary' disabled={false} icon='' onClickButton={handleLogin} title='Entrar' size='large' widthSize={"100%"} />

        <Button disabled={false} icon='' onClickButton={() => navigate('/signup')} size='large' title='Ainda não tem uma conta?' widthSize={100} type='secondary'></Button>

      </S.Content>
      <Alert />
      {isLoading ? <Loading text='Aguarde enquanto carregamos as suas informações.' /> : null}
      <WhatsappButton />
    </S.Container>
  );
};
