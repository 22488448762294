import { NEUTRAL_COLORS } from "constants/styleConstants"

type GadgetProps = {
    building: string
}

const suytsLogo = require('assets/image/suyts-logo.png')
const yoghaLogo = require('assets/svg/Logo.svg').default

export default function Gadget({ building }: GadgetProps) {
    return (
        <div style={{
            display: 'flex',
            position: 'absolute',
            width: '64px',
            bottom: 36,
            right: 16,
            padding: '10px',
            borderRadius: '10px',
            border: `1px solid ${NEUTRAL_COLORS.LIGHT_GRAY}`,
            backgroundColor: 'white'
        }}>
            {Number(building) === 38 ? <img style={{
                width: '100%',
                height: '100%'
            }} src={suytsLogo} alt="" /> : <img style={{
                width: '100%',
                height: '100%'
            }} src={yoghaLogo} alt="" />}
        </div>

    )
}