import { useEffect, useRef } from 'react';
import * as S from './styles';

export interface UserDropdownMenuProps {
  isOpen?: boolean;
  setIsOpen?: (open: boolean) => void;
  children?: React.ReactNode;
  containerModal?: any
}

export const UserDropdownMenu = ({ containerModal, children, isOpen, setIsOpen }: UserDropdownMenuProps) => {

  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (isOpen && dropdownMenuRef.current && !dropdownMenuRef.current.contains(e.target as Node)) {
      if (setIsOpen)
        setIsOpen(false);
    }
    else if (!isOpen && containerModal?.current && containerModal.current.contains(e.target as Node)) {
      if (setIsOpen)
        setIsOpen(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen, setIsOpen, containerModal]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEscapeKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && setIsOpen) setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleEscapeKey]);

  return (
    <>
      {isOpen && (
        <S.Overlay ref={dropdownMenuRef}>
          <S.Container>
            <S.Content>{children}</S.Content>
          </S.Container>
        </S.Overlay>
      )}
    </>
  );
};
