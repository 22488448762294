import { useEffect } from "react"
import { Button } from "components/Button"
import * as S from './styles'
import { Dropdown, SelectOption } from "components/Dropdown"
import { useState } from "react"
import { useUserProvider } from "hooks/userContext"
import { usePaymentProvider } from "context/paymentContext"
import fetchAxios, { fetchAxiosAdmin, fetchAxiosPayment } from "services/http"
import useAlert from "components/Notification"
import { useNavigate } from "react-router-dom"
import { useAnyProvider } from "hooks/Context"
import { SkeletonPayment } from "../../components/SkeltonPayment"
import { useFetchingPayment } from "../.."
import { useScreenWidth } from "utils/useScreenWidth"
import axios, { AxiosError } from "axios"
import { useSessionStorage } from "context/sessionStorage"
import { subtractDates, useDateDifference } from "hooks/useDateDifference"
import { BlockedUserAlert } from "../../components/BlockedUserAlert"
import { ReserverDataProps } from "pages/Product/types"
import { useDiscountCookie } from "pages/Payment/components/PaymentCard/useDiscountCupomCookie"
import usePaymentStore, { DataSessionPaymentProps } from "pages/Payment/components/PaymentCard/useStorage"
import { useGuestStore } from "components/Header/components/useGuestStorage"

interface PaymentValues {
    installmentCount: string;
    installmentValue: string;
    totalValue: string;
}
export const CreditPayment = () => {

    const { isFetching, setIsFetching } = useFetchingPayment()
    const { saveToSessionStorage, getFromSessionStorage } = useSessionStorage()
    const { date } = useAnyProvider()

    const { adults, children, babies, totalGuests } = useGuestStore();

    const { dayDifference: difference } = useDateDifference(date);

    const { Alert, showAlert } = useAlert({ active: false, type: 'SUCCESS', title: '' })

    const { discount: cupom, cupomId } = useDiscountCookie()

    const cupomValue = cupom ? Number(cupom) : 0

    const breakpoint = useScreenWidth()

    const [parcel, setParcel] = useState<SelectOption>()
    const [cardInfo, setCardInfo] = useState<any>()
    const [paymentInfo, setPaymentInfo] = useState<any>()
    const [reserverInfo, setReserverInfo] = useState<any>()
    const [blockedUser, setBlockedUser] = useState<boolean>(false)

    const { paymentData } = usePaymentStore()

    function isSpecificAxiosErrorMessage(error: unknown): boolean {
        const axiosError = error as AxiosError;
        return !!axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Cliente bloqueado, favor entrar em contato com o suporte.";
    }

    const [parcelNumber, setParcelNumber] = useState<SelectOption[]>([])

    const [paymentsValues, setPaymentValues] = useState<PaymentValues>({
        installmentCount: '',
        installmentValue: '',
        totalValue: ''
    })

    const { token, customer, userCustomerId, userSurname, userPhone, userName, userDocument, userEmail, userAccessToken } = useUserProvider()

    const { creditCard, setIsLoading } = usePaymentProvider()


    const { totalValue, creditCardToken } = usePaymentProvider()

    const navigate = useNavigate()

    const totalWithCupom = () => {
        if (paymentData === null) return 0
        const discountByTotal = paymentData?.total * (cupomValue / 100)
        const newTotalValue = paymentData?.total - discountByTotal
        return newTotalValue
    }

    const cupomTotalValue = totalWithCupom()

    useEffect(() => {
        const getCreditParcel = async () => {
            const returnCard = getFromSessionStorage('cardsPayment')
            const paymentValue = getFromSessionStorage('payment')
            const reserver: ReserverDataProps = getFromSessionStorage('reserver')
            setReserverInfo(reserver)
            setCardInfo(returnCard)

            const withoutInstallment = [{
                parcela: 1,
                valor_parcela: cupomValue > 0 ? String(cupomTotalValue) : String(totalValue === 0 ? paymentData?.total : totalValue),
                total: cupomValue > 0 ? String(cupomTotalValue) : String(totalValue === 0 ? paymentData?.total : totalValue)
            }]
            // setIsLoading(true)
            setIsFetching(true)
            try {
                const formData = new FormData();
                formData.append("token", token ? token : returnCard?.token);
                formData.append("total", cupomValue > 0 ? String(cupomTotalValue) : String(totalValue === 0 ? paymentData?.total : totalValue));

                const { data } = await fetchAxiosPayment.post('/installments', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                })
                const newOptionWithoutInstallment = [...withoutInstallment, ...data.valores]
                const newOpt = newOptionWithoutInstallment.map((item: any) => {
                    return {
                        value: item.total,
                        label: `Você pagará em ${item.parcela}x de R$ ${Number(item.valor_parcela).toLocaleString(
                            'pt-BR',
                            {
                                minimumFractionDigits: 2,
                            },
                        )} no Total R$ ${Number(item.total).toLocaleString(
                            'pt-BR',
                            {
                                minimumFractionDigits: 2,
                            },
                        )}`,
                        valor_parcela: item.valor_parcela,
                        parcela: item.parcela
                    }
                })
                setParcelNumber(newOpt)

                // setIsLoading(false)
                // navigate('/payment/PIX_CODE/'+ id)
            } catch (error) {
                console.log(error, 'error')
            } finally {
                setIsFetching(false)
                setIsLoading(false)
            }
        }
        getCreditParcel()
    }, [cupomValue])

    const today = new Date().toISOString().split('T')[0];

    const cupomDiscountedNightValue = (data: DataSessionPaymentProps) => {
        const discountTotal = data.total * (cupomValue / 100);
        const newNightValue = data.nightValue - discountTotal;
        return newNightValue;
    }

    const cupomDiscountedNightValueMidStay = (data: DataSessionPaymentProps) => {
        const sumAllValues = data.midStayValue + data.condominiumValue + data.iptu + data.cleaningValue + data.pacoteYogha
        const discountTotal = sumAllValues * (cupomValue / 100);
        const newValueMidStay = data.midStayValue - discountTotal;
        return newValueMidStay;
    }

    const handleReserv = async () => {
        if (paymentsValues.installmentCount === '') {
            return showAlert({ active: true, type: 'ERROR', title: 'Por favor, selecione a quantidade de parcelas a pagar!' });
        }
        const isShortStay = subtractDates(reserverInfo.arrival_date, reserverInfo.departure_date) < 27 ? 'Shortstay' : 'Midstay';

        setIsLoading(true)
        let reserveId;
        try {

            const description = `${reserverInfo.nameAcc} - ${reserverInfo.nameBuilding} | Data: ${reserverInfo.arrival_date} - ${reserverInfo.departure_date}`
            // eslint-disable-next-line no-mixed-operators
            if (reserverInfo.rental_price > 0) {
                const { data: dataReserve } = await fetchAxiosAdmin.post('site/set-booking', {
                    "accommodation_code": reserverInfo.accommodation_code,
                    "accommodation_id": reserverInfo.accommodation_id,
                    "arrival_date": reserverInfo.arrival_date,
                    "departure_date": reserverInfo.departure_date,
                    "booking_type": reserverInfo.booking_type,
                    "customer_id": userCustomerId !== '' ? userCustomerId : reserverInfo.user.customer_id,
                    "email": userEmail !== '' ? userEmail : reserverInfo.user.email,
                    "adults_number": reserverInfo.adults_number,
                    "name": userName !== '' ? userName : reserverInfo.user.name,
                    "web": "Yogha.com.br",
                    "ref_stays": reserverInfo.ref_stays,
                    "rental_price": difference < 27 && cupomValue > 0 ? cupomDiscountedNightValue(paymentData as DataSessionPaymentProps) : difference >= 27 && cupomValue > 0 ? cupomDiscountedNightValueMidStay(paymentData as DataSessionPaymentProps) : reserverInfo.rental_price,
                    "total_price": paymentsValues.totalValue.replace(',', ''),
                    "surname": userSurname !== '' ? userSurname : reserverInfo.user.surname,
                    "dni": userDocument !== '' ? userDocument : reserverInfo.user.dni,
                    "telephone": userPhone !== '' ? userPhone : reserverInfo.user.phone,
                    "services": reserverInfo.services,
                    "token": userAccessToken,
                    "discount": cupomValue > 0 ? cupomValue : 0
                })
                try {
                    const formData = new FormData();
                    formData.append("customer", customer);
                    formData.append("token", token);
                    formData.append("value", paymentsValues.totalValue.replace(',', ''));
                    formData.append("billingType", "CREDIT_CARD");

                    formData.append("dueDate", today);
                    formData.append("creditCardToken", cardInfo.token);
                    formData.append("installmentCount", paymentsValues.installmentCount);
                    formData.append("installmentValue", paymentsValues.installmentValue);
                    formData.append("booking", dataReserve.id);
                    formData.append("description", description);

                    const res = await fetchAxiosPayment.post('/charge', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    })
                    reserveId = res.data.charge_id
                    try {
                        const { data: reservationRef } = await fetchAxios.post(`/reservers/changeStatus/${dataReserve.id}`, {
                            status: 'PAID',
                        }, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        })
                        showAlert({ active: true, type: 'SUCCESS', title: 'Pagamento realizado com sucesso!' })
                        setIsLoading(false)
                        if ((window as any).dataLayer) {
                            (window as any).dataLayer.push({
                                'event': 'purchase',
                                'building': reserverInfo && reserverInfo.nameBuilding, //Building name//
                                'city': reserverInfo && reserverInfo.city, //Cidade do edifício//
                                'conversion_value': paymentsValues.totalValue.replace(',', ''), //Valor total da reserva//
                                'transaction_ID': reservationRef.localizator, //Identificação da reserva//
                                'currency': 'BRL', //Inicialmente, traremos apenas a currency 'BRL'//
                                'product_ID': reserverInfo && reserverInfo.accommodation_id, //Identificação da unidade//
                                'product_name': reserverInfo && reserverInfo.nameAcc, //Nome do imóvel//
                                'client_ID': userCustomerId, //Identificação do cliente na tabela customer//
                                'booking_type': isShortStay,
                                'guest_number': totalGuests, //Número de hóspedes//
                                'payment_type': 'Credit'
                            })
                        }
                        navigate(`/payment/${reserveId.replace('pay_', '')}/confirmado`)
                    } catch (error) {
                        const axiosError = error as AxiosError;
                        if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message ===
                            "Transação não autorizada. Verifique os dados do cartão de crédito e tente novamente."
                        ) {
                            navigate(`/payment/${reserveId.replace('pay_', '')}/erro-de-pagamento`)
                        } else {
                            navigate(`/payment/${reserveId.replace('pay_', '')}/erro-de-pagamento`)
                        }
                    }
                    try {

                        const { data: cupomData } = await fetchAxiosAdmin.post('cupons/create',
                            {
                                "idCupom": cupomId,
                                "idCustomer": userCustomerId,
                                "idBooking": dataReserve.id
                            }
                        )

                    } catch (error) {
                        console.log(error)
                    }
                } catch (error) {
                    const { data } = await fetchAxios.post(`/reservers/changeStatus/${dataReserve.id}`, {
                        status: 'CANCELLED',
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    navigate(`/payment/${reserveId.replace('pay_', '')}/erro-de-pagamento`)
                }
            } else if (paymentsValues.installmentCount === '1') {
                try {
                    const { data: dataReserve } = await fetchAxiosAdmin.post('site/set-booking', {
                        "accommodation_code": reserverInfo.accommodation_code,
                        "accommodation_id": reserverInfo.accommodation_id,
                        "arrival_date": reserverInfo.arrival_date,
                        "departure_date": reserverInfo.departure_date,
                        "booking_type": reserverInfo.booking_type,
                        "customer_id": userCustomerId !== '' ? userCustomerId : reserverInfo.user.customer_id,
                        "email": userEmail !== '' ? userEmail : reserverInfo.user.email,
                        "adults_number": reserverInfo.adults_number,
                        "name": userName !== '' ? userName : reserverInfo.user.name,
                        "web": "Yogha.com.br",
                        "ref_stays": reserverInfo.ref_stays,
                        "rental_price": difference < 27 && cupomValue > 0 ? cupomDiscountedNightValue(paymentData as DataSessionPaymentProps) : difference >= 27 && cupomValue > 0 ? cupomDiscountedNightValueMidStay(paymentData as DataSessionPaymentProps) : reserverInfo.rental_price,
                        "total_price": paymentsValues.totalValue.replace(',', ''),
                        "surname": userSurname !== '' ? userSurname : reserverInfo.user.surname,
                        "dni": userDocument !== '' ? userDocument : reserverInfo.user.dni,
                        "telephone": userPhone !== '' ? userPhone : reserverInfo.user.phone,
                        "services": reserverInfo.services,
                        "token": userAccessToken,
                        "discount": cupomValue > 0 ? cupomValue : 0
                    })
                    try {

                        const formData = new FormData();
                        formData.append("customer", customer);
                        formData.append("token", token);
                        formData.append("value", paymentsValues.totalValue.replace(',', ''));
                        formData.append("billingType", "CREDIT_CARD");
                        formData.append("dueDate", today);
                        formData.append("creditCardToken", creditCardToken)
                        formData.append("booking", dataReserve.id);
                        const res = await fetchAxiosPayment.post('/charge', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        })
                        reserveId = res.data.charge_id
                        try {
                            const { data: reservationRef } = await fetchAxios.post(`/reservers/changeStatus/${dataReserve.id}`, {
                                status: 'PAID',
                            }, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            })
                            showAlert({ active: true, type: 'SUCCESS', title: 'Pagamento realizado com sucesso!' })
                            setIsLoading(false)
                            if ((window as any).dataLayer) {
                                (window as any).dataLayer.push({
                                    'event': 'purchase',
                                    'building': reserverInfo && reserverInfo.nameBuilding, //Building name//
                                    'city': reserverInfo && reserverInfo.city, //Cidade do edifício//
                                    'conversion_value': reserverInfo && reserverInfo.total_price, //Valor total da reserva//
                                    'transaction_ID': reservationRef.localizator, //Identificação da reserva//
                                    'currency': 'BRL', //Inicialmente, traremos apenas a currency 'BRL'//
                                    'product_ID': reserverInfo && reserverInfo.accommodation_id, //Identificação da unidade//
                                    'product_name': reserverInfo && reserverInfo.nameAcc, //Nome do imóvel//
                                    'client_ID': userCustomerId, //Identificação do cliente na tabela customer//
                                    'booking_type': isShortStay,
                                    'guest_number': totalGuests, //Número de hóspedes//
                                    'payment_type': 'Credit'
                                })
                            }
                            navigate(`/payment/${reserveId.replace('pay_', '')}/confirmado`)
                        } catch (error) {
                            console.error(error)
                        }
                        try {
                            const { data: cupomData } = await fetchAxiosAdmin.post('cupons/create',
                                {
                                    "idCupom": cupomId,
                                    "idCustomer": userCustomerId,
                                    "idBooking": dataReserve.id
                                }
                            )
                        } catch (error) {
                            console.error(error)
                        }
                        showAlert({ active: true, type: 'SUCCESS', title: 'Pagamento realizado com sucesso!' })
                        setIsLoading(false)
                        navigate(`/payment/${reserveId.replace('pay_', '')}/confirmado`)
                    } catch (error) {
                        const { data } = await fetchAxios.post(`/reservers/changeStatus/${dataReserve.id}`, {
                            status: 'CANCELLED',
                        }, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        })
                        navigate(`/payment/${reserveId.replace('pay_', '')}/erro-de-pagamento`)
                    }

                } catch (error: unknown) {
                    if (isSpecificAxiosErrorMessage(error)) {
                        setIsLoading(false)
                        setBlockedUser(true)
                    } else {
                        navigate(`/payment/${userCustomerId.replace('pay_', '')}/erro-de-pagamento`)
                    }
                }
            }

        } catch (error: unknown) {
            if (isSpecificAxiosErrorMessage(error)) {
                setIsLoading(false)
                setBlockedUser(true)
            } else {
                showAlert({ active: true, type: 'ERROR', title: 'Erro ao realizar pagamento!' })
                setIsLoading(false)
                navigate(`/payment/${userCustomerId.replace('pay_', '')}/erro-de-pagamento`)
            }
        }


    }

    const onChangeValuesPayment = (e: any) => {
        setPaymentValues({
            installmentCount: e.parcela,
            installmentValue: e.valor_parcela,
            totalValue: e.value
        })
        setParcel(e)
    }
    const getFlagCard = (brand: string) => {
        switch (brand) {
            case 'VISA':
                return require('assets/svg/flag/visa.svg').default
            case 'MASTERCARD':
                return require('assets/svg/flag/mastercard.svg').default
            case 'HIPERCARD':
                return require('assets/svg/flag/hipercard.svg').default
            case 'JCB':
                return require('assets/svg/flag/jcb.svg').default
            case 'BANESCARD':
                return require('assets/svg/flag/banescard.svg').default
            case 'AMEX':
                return require('assets/svg/flag/amex.svg').default
            case 'DISCOVER':
                return require('assets/svg/flag/discover.svg').default
            case 'ELO':
                return require('assets/svg/flag/elo.svg').default
            case 'CABAL':
                return require('assets/svg/flag/cabal.svg').default
            case 'PIX':
                return require('assets/svg/payment/PixIcon.svg').default
        }

    }

    return (
        <S.ContainerCreditPayment>
            {isFetching ? (
                <SkeletonPayment />
            ) : (
                <S.SelectContainer>
                    <S.Dropdown>
                        <h4>Forma de pagamento escolhido</h4>

                        <S.InputPayment>
                            <S.InputRow>
                                <img src={getFlagCard(creditCard.brand ? creditCard.brand : cardInfo?.brand)} alt="" />
                                {
                                    creditCard.brand === '' ? (
                                        <p>{cardInfo?.brand} **** {cardInfo?.lastNumbers}</p>

                                    ) : (
                                        <p>{creditCard.brand} **** {creditCard.lastNumbers}</p>

                                    )
                                }
                            </S.InputRow>
                            <div style={{ alignSelf: 'flex-end' }}>
                                <S.ButtonContainer to={{
                                    pathname: '/payment',
                                }}>
                                    <Button onClickButton={() => { }} size='small' title='Alterar' type='secondary' heightSize={"30px"} />
                                </S.ButtonContainer>
                            </div>
                        </S.InputPayment>
                    </S.Dropdown>
                    <S.Dropdown>
                        <h4>Forma de pagamento escolhido</h4>


                        {<Dropdown expanded={false} placeholder={`Em quantas parcelas você quer pagar?`} onChange={(e) => onChangeValuesPayment(e)} type='dropdown' options={parcelNumber} widthDrop={breakpoint ? 'calc(100% - 32px)' : 690 - 32} />}
                    </S.Dropdown>
                    <S.ButtonConclude>
                        <Button onClickButton={handleReserv} size='medium' title='Confirmar pagamento' type='primary' widthSize={'100%'} />
                    </S.ButtonConclude>
                </S.SelectContainer>
            )}
            {/* <RegisterCreditCard /> */}
            <Alert />
            <BlockedUserAlert blockedUser={blockedUser} setIsBlockedUser={setBlockedUser} />
        </S.ContainerCreditPayment>
    )

}
