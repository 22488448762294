import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const BackDrop = styled.div`
    display: flex;

    position: fixed;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 1000;

    backdrop-filter: blur(4px);
`;

export const SubmitButton = styled.button`
    display: flex;
    width: fit-content;

    justify-content: center;
    align-items: center;

    padding: 0px 16px;
    height: 44px;

    border: none;

    background-color: ${PRIMARY_COLORS.MEDIUM};
    color: ${NEUTRAL_COLORS.WHITE};
    ${FONT.BODY.BODY_SMALL[600]}
    border-radius: 10px;
    cursor: pointer;
`;

export const ModalCard = styled.div`
    display: flex;
    flex-direction: column;

    position: fixed;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: max-content;
    height: max-content;

    justify-content: space-between;

    background-color: ${NEUTRAL_COLORS.WHITE};

    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    border-radius: 16px;

    z-index: 1000;

`;

export const Header = styled.div<{loading:number}>`
    display: flex;

    gap: 80px;

    position: relative;

    align-items: center;
    justify-content: flex-start;

    margin-bottom: 28px;


    ::after{
        content: '';

        display: block;

        top: 40px;
        left: 0;

        position: absolute;

        margin-left: -24px;

        height: 3px;
        ${({loading})=> loading === 1 ? `
            width: 186px;
        ` : loading === 2 ? `
            width: 372px;
        ` : loading === 3 ? `
            width: 558px;
        ` : ``}

        background: linear-gradient(279deg, #FF7A2A 0%, #FFC120 100%);;
    }

    ::before{
        content: '';

        display: block;

        top: 42px;
        left: 0;

        position: absolute;

        margin-left: -24px;

        height: 1px;
        width: calc(100% + 48px);

        background: ${NEUTRAL_COLORS.GRAY};
    }

`;

export const CollumnModalValidateValue = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 36px;

    max-width: 690px;
    height: 415px;
`;

export const Stages = styled.div`
    display: flex;

    gap:8px;

    align-items: center;
`

export const StageArea = styled.p<{disable:boolean, passed?:boolean}>`
    ${FONT.BODY.BODY_LARGE[600]};
    color:${NEUTRAL_COLORS.HIGH_DARK};
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: column;

    position: relative;

    padding: 16px 24px;
`;

export const CollumnModal = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    gap: 32px;
`;

export const CollumnModalSecond = styled.div`
    display: flex;
    flex-direction: row;

    gap: 32px;

    width: fit-content;
`;

export const HeaderValue = styled.div`
    display: flex;
    flex-direction: column;

    margin-right: 24px;

    row-gap: 16px;

    ${FONT.BODY.BODY_SMALL[400]};
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 24px;

    justify-content: flex-start;


    margin-top: 16px;
`;

export const WarningRow = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 10px;

    p{
       ${FONT.BODY.BODY_SMALL[500]}
       color: ${PRIMARY_COLORS.MEDIUM};
       cursor: pointer;
    }
    img{
        cursor: pointer;
    }

`;

export const SecurityText = styled.div`
    display: flex;

    width:405px;

    text-align: center;

    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
    }
    margin-block: -12px -16px;
`;

export const SecurityContent = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 32px;

    align-items: center;

    justify-content: center;

    margin-top: 16px;
`;

export const ColumnValue = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;
`;

export const CloseButton = styled.button`
   width: 24px;
    height: 24px;

    background-color: transparent;

    border: none;

    position: absolute;
    top: 16px;
    right: 16px;

    cursor: pointer;

`;

export const RowCardInfo = styled.div`
    display: flex;

    align-items: flex-start;

    gap: 16px;
`;

export const FlagsContainer = styled.div`
display: inline-flex;
flex-direction: column;

align-items: flex-start;

row-gap: 8px;
`;

export const Flags = styled.div`
display: flex;

align-items: center;

gap: 4px;
`;

export const ContainerCard = styled.div`
    display: flex;
    flex-direction: column;

    gap: 56px;

    justify-content: flex-start;
    margin-top: 86px;

`;

export const HeadlineTop = styled.h2`
    ${FONT.BODY.BODY_LARGE[600]};
`

export const  ModalFooter = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: flex-end;

    gap: 8px;

    padding: 16px 16px;

    border-top: 1px solid ${NEUTRAL_COLORS.GRAY};
`;

export const WarningValidation = styled.div`
    display: flex;

    flex-direction: column;

    row-gap: 8px;

    margin-top: 32px;

    p{
        white-space: normal;
        ${FONT.BODY.BODY_SMALL[400]};
    }

    max-width: 690px;
`;

export const WarningHeader  = styled.div`
    display: flex;

    gap: 8px;

    span{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: ${STATUS_COLORS.ERROR};
    }

    align-items: center;
`;