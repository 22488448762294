import { useState, useEffect } from 'react';

// Funções auxiliares para manipular cookies
const setCookie = (name: string, value: string) => {
    const expires = new Date();
    expires.setHours(expires.getHours() + 1); // Cookie válido por 1 dia
    if (process.env.NODE_ENV !== 'development') {
        document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/; SameSite=Strict`;
    } else {
        document.cookie = `${name}=${value}; path=/;`;
    }
};

const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
    return undefined;
};


const removeCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; SameSite=Strict`;
};

export const useDiscountCookie = () => {
    const [discount, setDiscount] = useState<string | undefined>(getCookie('discount'));
    const [cupomId, setCupomId] = useState<string | undefined>(getCookie('cupomId'));
    const [isDiscountValid, setIsDiscountValid] = useState<boolean>(getCookie('isDiscountValid') === 'true');

    useEffect(() => {
        const handleCookieChange = () => {
            setDiscount(getCookie('discount'));
            setIsDiscountValid(getCookie('isDiscountValid') === 'true');
            setCupomId(getCookie('cupomId'));
        };

        window.addEventListener('cookieChange', handleCookieChange);

        return () => {
            window.removeEventListener('cookieChange', handleCookieChange);
        };
    }, []);

    const saveDiscount = (discountValue: string, isValid: boolean, cupomId: string) => {
        setCookie('discount', discountValue);
        setCookie('isDiscountValid', isValid.toString());
        setCookie('cupomId', cupomId);
        window.dispatchEvent(new Event('cookieChange'));
    };

    const clearDiscount = () => {
        removeCookie('discount');
        removeCookie('cupomId');
        removeCookie('isDiscountValid');
        setDiscount(undefined);
        setIsDiscountValid(false);
        window.dispatchEvent(new Event('cookieChange'));
    };

    return { discount, isDiscountValid, saveDiscount, clearDiscount, cupomId };
};
