import { useCallback } from 'react';
import * as S from './styles'
import { useDiscountCookie } from './useDiscountCupomCookie';
import usePaymentStore, { DataSessionPaymentProps } from './useStorage';
import { formatValue } from 'utils/formatValue';
interface RenderShortStayProps {
    discount: boolean;
    nights: number;
    dataPayment: DataSessionPaymentProps;
    discountDifference: number;
    discountValue: number;
    hasCodePix: string | null;
    typePayment: string;
    totalValue: number;
    weekValue: number;
    difference: number;
}

const NightValueDisplay = ({ value, discount }: { value: number; discount: boolean }) => (
    <S.ContainerValues discount={discount}>
        {discount && <span>De</span>}
        <S.ContainerPixDiscount discount={discount}>
            <S.RowTotalValue>
                <S.Prefix>R$</S.Prefix>
                <S.RowValues>{formatValue(value)}</S.RowValues>
            </S.RowTotalValue>
        </S.ContainerPixDiscount>
    </S.ContainerValues>
);

export const useRenderShortStay = ({
    discount,
    dataPayment,
    hasCodePix,
    typePayment,
    difference,
    weekValue
}: RenderShortStayProps) => {
    const {
        pixDiscountedTotalValue,
        pixDiscountedNightValue,
        recoverNightByPixCode,
        recoverTotalValueByPixCode,
        recoverTotalValueWithCupom,
    } = usePaymentStore();

    const { discount: cupom, isDiscountValid } = useDiscountCookie();
    const cupomValue = cupom ? Number(cupom) : 0;

    const calculateTotalValueWithCupom = (): number => {
        if (!dataPayment) return 0;
        const discountByTotal = dataPayment.total * (cupomValue / 100);
        return (dataPayment.nightValue - discountByTotal) + dataPayment.cleaningValue;
    };

    const getCalculatedNightValue = (): number => {
        if (!dataPayment) return 0;
        if (dataPayment?.pixCode) {
            return dataPayment.nightValue
        }
        if (hasCodePix) {
            return recoverNightByPixCode();
        }
        return pixDiscountedNightValue({
            typePayment,
            hasCupom: isDiscountValid,
            cupomValue
        });
    };

    const getTotalValue = useCallback((): number => {
        if (!dataPayment) return 0;

        if (hasCodePix === 'recoverTotalValueUndiscounted' && dataPayment.totalValueUndiscounted) {
            return dataPayment.totalValueUndiscounted;
        }
        if (hasCodePix === 'pixWithCupom' && cupomValue) {
            const totalWithCupom = recoverTotalValueWithCupom(cupomValue);

            return totalWithCupom;
        }
        if (hasCodePix === 'true') {
            const totalWithPixCode = recoverTotalValueByPixCode();
            return totalWithPixCode;
        }
        return dataPayment.total;
    }, [hasCodePix, cupomValue, dataPayment, recoverTotalValueWithCupom, recoverTotalValueByPixCode]);

    const getDiscountedTotal = (): number => {
        if (!dataPayment) return 0;
        if (cupomValue > 0) {
            return calculateTotalValueWithCupom();
        }
        if (hasCodePix) {
            return dataPayment.total;
        }
        return pixDiscountedTotalValue(typePayment, dataPayment.pixCode);
    };

    const RenderShortStayCard = () => {
        if (!dataPayment) return null;

        const nightsCount = difference === 0 ? dataPayment?.difference : difference;
        const cleaningCount = weekValue === 0 ? dataPayment?.weekValue : weekValue;
        const cleaningValue = weekValue !== 0 ? dataPayment?.cleaningValue : dataPayment?.cleaningValue
        const hasDiscount = typePayment === 'PIX' || !!hasCodePix || cupomValue > 0;

        return (
            <S.PaymentContainer>
                <S.Rows discount={discount}>
                    <p>{nightsCount} Noites</p>
                    <NightValueDisplay value={getCalculatedNightValue()} discount={discount} />
                </S.Rows>

                <S.Rows>
                    <p>{cleaningCount} Limpeza{cleaningCount > 1 && 's'}</p>
                    <S.ContainerValues>
                        <label>R$</label>
                        <S.RowValues>{formatValue(cleaningValue ?? 0)}</S.RowValues>
                    </S.ContainerValues>
                </S.Rows>

                <S.Rows>
                    <p>Pacote Yogha</p>
                    <S.ContainerValues include>
                        <label>incluso</label>
                    </S.ContainerValues>
                </S.Rows>

                <S.TotalRow>
                    <S.Total>Total</S.Total>
                    <S.ContainerPixDiscount>
                        <S.RowTotalValue>
                            <S.PrefixTotal hasDiscount={hasDiscount}>R$</S.PrefixTotal>
                            <S.TotalValue hasDiscount={hasDiscount}>
                                {formatValue(getTotalValue())}
                            </S.TotalValue>
                        </S.RowTotalValue>

                        {hasDiscount && (
                            <S.RowTotalValue>
                                <S.PrefixTotal>R$</S.PrefixTotal>
                                <S.TotalValue>
                                    {formatValue(getDiscountedTotal())}
                                </S.TotalValue>
                            </S.RowTotalValue>
                        )}
                    </S.ContainerPixDiscount>
                </S.TotalRow>
            </S.PaymentContainer>
        );
    };

    return { RenderShortStayCard };
};