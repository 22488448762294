import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "constants/styleConstants";
import styled, { css } from "styled-components";

export const Header = styled.div`
    display: flex;

    width: 100%;

    position: fixed;
    top: 0px;
    left: 0px;

    align-items: center;
    justify-content: center;

    min-height: 86px;
    background-color: ${NEUTRAL_COLORS.WHITE};

    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);

    z-index:100;

    @media (width < 768px) {
        min-height: 62px;
        height: 62px;
    }

`;

export const SubmitButton = styled.button`
    display: flex;
    width: fit-content;

    justify-content: center;
    align-items: center;

    padding: 0px 16px;
    height: 44px;

    border: none;

    background-color: ${PRIMARY_COLORS.MEDIUM};
    color: ${NEUTRAL_COLORS.WHITE};
    ${FONT.BODY.BODY_SMALL[600]}
    border-radius: 10px;
    cursor: pointer;
`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    align-items: center;

  @media (width < 768px) {

    width: calc(100% - 40px);

    position: relative;

    max-width: auto;

    justify-content: center;

  }

`;

export const BackArrow = styled.img`
display: none;

@media (width < 768px) {
 display: block;

 position: absolute;
 top:0px;
 left: 0px;

 width: 18px;
 height: 15px;

 object-fit: contain;

 cursor: pointer;

 padding: 8px;

}

`;

export const Logo = styled.img`
    width:112px;
    height: 40px;
    padding-bottom: 10px;
    padding-left: 1px;

    cursor: pointer;

    @media (width < 768px) {

        padding:0px;
        width:87px;
        height: 30px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: calc(100vw - 40px);
    height: 100vh;

    padding: 0px 20px;

    background-color: ${NEUTRAL_COLORS.WHITE};
    overflow-y: auto;
`;


export const CollumnModalValidateValue = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: 36px;
`;

export const Stages = styled.div`
    display: flex;

    gap:8px;

    align-items: center;
`

export const StageArea = styled.p<{disable:boolean, passed?:boolean}>`
    ${FONT.BODY.BODY_LARGE[600]};
    color:${NEUTRAL_COLORS.HIGH_DARK};
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    margin-top: 16px;

`;

export const CollumnModal = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
`;

export const CollumnModalSecond = styled.div`
    display: flex;
    flex-direction: row;

    gap: 32px;

    width: 100%;
`;

export const HeaderValue = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;

    ${FONT.BODY.BODY_SMALL[400]};
`;

export const InputContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;

    row-gap: 24px;
`;

export const WarningRow = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    gap: 10px;

    p{
       ${FONT.BODY.BODY_SMALL[500]}
       color: ${PRIMARY_COLORS.MEDIUM};
       cursor: pointer;
    }
    img{
        cursor: pointer;
    }

`;

export const SecurityText = styled.div`
    display: flex;

    width:405px;

    text-align: center;

    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
    }
    margin-block: -12px -16px;
`;

export const SecurityContent = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 32px;

    align-items: center;

    justify-content: center;

    margin-top: 16px;
`;

export const ColumnValue = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;

`;


export const RowCardInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    gap: 16px;
`;

export const FlagsContainer = styled.div`
display: inline-flex;
flex-direction: column;

align-items: flex-start;

row-gap: 8px;

margin-top: 40px;
margin-bottom: 36px;
`;

export const Flags = styled.div`
display: flex;

align-items: center;

gap: 4px;
`;

export const Title = styled.h1`
    ${FONT.BODY.BODY_LARGE[600]};
    margin-top: 86px;
    align-self: center;
    `;

export const ContainerCard = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    justify-content: flex-start;
    margin-top: 64px;
`;

export const HeadlineTop = styled.h2`
    ${FONT.BODY.BODY_LARGE[600]};
`

export const  ModalFooter = styled.div<{stage:number}>`
    display: flex;
    flex-direction: column;

    width: 100%;

    justify-content: center;

    gap: 8px;

    padding: 16px 0px;
    padding-bottom: 32px;

    border-top: 1px solid ${NEUTRAL_COLORS.GRAY};

 ${({stage}) => stage === 1 ? css`
    margin-top: 140px;
 ` : stage === 2 ? css`
    margin-top: 64px;
 ` : ``}

    padding-bottom: 140px;
`;

export const WarningValidation = styled.div`
    display: flex;

    flex-direction: column;

    row-gap: 8px;

    margin-top: 32px;
    margin-bottom: 16px;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
    }
`;

export const WarningHeader  = styled.div`
    display: flex;

    gap: 8px;

    span{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: ${STATUS_COLORS.ERROR};
    }

    align-items: center;
`;

export const TextHeading = styled.strong`
    margin-top: 16px;
    text-align: center;
    ${FONT.BODY.BODY_SMALL[400]};
    font-weight: 400 !important;
`;

export const ButtonContainer = styled.div`
    display: flex;

    justify-content: end;
`;