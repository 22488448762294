import React, { createContext, useContext, useState } from 'react';
import { useLocalStorageState } from './useLocalStorageState';

export type UserContextType = {
  userName: string;
  userSurname: string;
  userEmail: string;
  userPhone: string;
  userAvatar: string;
  userDocument: string;
  userCustomerId: string;
  userAccessToken: string;
  token: string;
  customer: string;
  statusAccount: string;
  cardPaymentBlock: string;
  setStatusAccount: (newState: string) => void;
  setAccessToken: (newState: string) => void;
  setUserName: (newState: string) => void;
  setUserSurname: (newState: string) => void;
  setUserEmail: (newState: string) => void;
  setUserPhone: (newState: string) => void;
  setUserAvatar: (newState: string) => void;
  setUserDocument: (newState: string) => void;
  setUserCustomerId: (newState: string) => void;
  setToken: (newState: string) => void;
  setCustomer: (newState: string) => void;
  setCardPaymentBlock: (newState: string) => void;
};

export const UserContext = createContext<UserContextType | null>(null);

export const UserProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [userAccessToken, setAccessToken] = useLocalStorageState('userAccessToken', '');
  const [userName, setUserName] = useLocalStorageState('userName', '');
  const [userEmail, setUserEmail] = useLocalStorageState('userEmail', '');
  const [token, setToken] = useLocalStorageState('token', '');
  const [customer, setCustomer] = useLocalStorageState('customer', '');
  const [userPhone, setUserPhone] = useLocalStorageState('userPhone', '');
  const [userDocument, setUserDocument] = useLocalStorageState('userDocument', '');
  const [userAvatar, setUserAvatar] = useLocalStorageState('userAvatar', '');
  const [userSurname, setUserSurname] = useLocalStorageState('userSurname', '');
  const [userCustomerId, setUserCustomerId] = useLocalStorageState('userCustomerId', '');
  const [statusAccount, setStatusAccount] = useLocalStorageState('userStatusAccount', '');
  const [cardPaymentBlock, setCardPaymentBlock] = useLocalStorageState('cardPaymentBlock', 'false');

  const contextValue: UserContextType = {
    userAccessToken,
    setAccessToken,
    userName,
    setUserName,
    userEmail,
    setUserEmail,
    token,
    setToken,
    customer,
    setCustomer,
    userPhone,
    setUserPhone,
    userDocument,
    setUserDocument,
    userAvatar,
    setUserAvatar,
    userSurname,
    setUserSurname,
    userCustomerId,
    setUserCustomerId,
    statusAccount,
    setStatusAccount,
    cardPaymentBlock,
    setCardPaymentBlock
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserProvider = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserProvider must be used within a UserProvider');
  }
  return context;
};
