import styled from 'styled-components';
import { STATUS_COLORS, PRIMARY_COLORS, NEUTRAL_COLORS } from '../../constants/styleConstants';
import { FONT_SIZE, FONT_WEIGTH } from '../../constants/fontConstants';
import { ButtonProps } from './types';

export const Container = styled.div<Omit<ButtonProps,'icon' | 'title' | 'onClickButton'>>`
  display: flex;

  flex-direction: ${props => (props.iconDirection === 'left' && 'row') || (props.iconDirection === 'right' && 'row-reverse')};

  justify-content: center;
  align-items: center;

  gap: 10px;

  padding: 0 16px;

  height: ${props => props.size === 'large' ? '52px' : props.heightSize ? props.heightSize : props.size === 'small' ? '36px' : props.size === 'medium' ? '44px' : ''};

  background-color: ${props => props.disabled ? STATUS_COLORS.DISABLED : props.type === 'secondary' ? NEUTRAL_COLORS.WHITE : props.type === 'confirm' ? STATUS_COLORS.ERROR : PRIMARY_COLORS.MEDIUM};

  color: ${props => props.disabled && props.type !== 'primary' ? STATUS_COLORS.DISABLED : props.type === 'primary' ? NEUTRAL_COLORS.WHITE : props.type === 'confirm' ? NEUTRAL_COLORS.WHITE : PRIMARY_COLORS.MEDIUM};

  font-size: ${props => props.size === 'large' ? FONT_SIZE.SMALL : FONT_SIZE.TINY};
  font-weight: ${FONT_WEIGTH.HUGE};

  border-radius: 10px;

  &:hover {
    background-color: ${props => props.disabled ? STATUS_COLORS.DISABLED : props.type === 'secondary' ?  'transparent' : props.type === 'confirm' ? STATUS_COLORS.ERROR_HOVER : PRIMARY_COLORS.DARK } ;
    color: ${props => props.type === 'secondary' ? PRIMARY_COLORS.DARK: ''};
    transition:  0.3s;
  }

  &:active {
    background-color: ${props =>  props.type === 'confirm' && STATUS_COLORS.ERROR_ACTIVE} ;
  }

  cursor: ${props => props.disabled ? null : 'pointer'};

  width: ${props => props.widthSize && `calc(${props.widthSize} - 32px)`};

  white-space: nowrap;
`;

export const Icon = styled.img`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;