import { useSessionStorage } from "context/sessionStorage"
import dayjs from "dayjs"
import { useAnyProvider } from "hooks/Context"
import { useDateDifference, subtractDates } from "hooks/useDateDifference"
import { useUserProvider } from "hooks/userContext"
import { useFetchingPayment } from "pages/Payment"
import { useDiscountCookie } from "pages/Payment/components/PaymentCard/useDiscountCupomCookie"
import usePaymentStore, { DataSessionPaymentProps } from "pages/Payment/components/PaymentCard/useStorage"
import { usePaymentTypeProvider } from "pages/Payment/hooks/usePaymentType"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import fetchAxios from "services/http"

export const usePixCode = () => {
    const navigate = useNavigate()

    const { isFetching, setIsFetching } = useFetchingPayment()

    const { setPaymentData, paymentData } = usePaymentStore()

    const { saveDiscount } = useDiscountCookie()

    const { saveToSessionStorage, removeFromSessionStorage } = useSessionStorage()

    const { setTypePayment } = usePaymentTypeProvider()
    const [qrCode, setQrCode] = useState<string>('')
    const [inlineCode, setInlineCode] = useState<string>('')
    const [copy, setCopy] = useState<string>('Copiar')
    const [tokenAsaas, setTokenAsaas] = useState<string>('')
    const [validTimePix, setValidTimePix] = useState<string>('')
    const [validDayPix, setValidDayPix] = useState<string>('')
    const { token, userCustomerId } = useUserProvider()
    const [localizator, setLocalizator] = useState<string>('')
    const [newPaymentData, setNewPaymentData] = useState<any>(null)


    const { date, setDate } = useAnyProvider()

    const { dayDifference: difference, weeklyDifference: weekValue } = useDateDifference(date)

    useEffect(() => {
        setPaymentData(newPaymentData);
    }, [newPaymentData])

    const validTimePixSumTwoHours = (createdPix: string) => {
        const createdDate = new Date(createdPix);
        createdDate.setHours(createdDate.getHours()); // Adjust for time difference
        const expiryDate = new Date(createdDate.getTime() + 2 * 60 * 60 * 1000); // add 2 hours to createdDate
        localStorage.setItem('expiryPix', expiryDate.toISOString());

        const now = new Date();
        let remainingTime = Math.floor((expiryDate.getTime() - now.getTime()) / 1000); // get remaining time in seconds

        if (remainingTime < 0) {
            // If remainingTime is negative, then the expiry time has passed
            remainingTime = 0;
        }

        let hours = Math.floor(remainingTime / 3600);
        let minutes = Math.floor((remainingTime % 3600) / 60);

        // Preenche com zeros à esquerda, se necessário
        hours = Number(hours.toString().padStart(2, '0'));
        minutes = Number(minutes.toString().padStart(2, '0'));

        const validHour = `${hours}h:${minutes}m`
        setValidDayPix(expiryDate.toISOString())
        setValidTimePix(validHour)
    }

    const pathname = useLocation().pathname.split('/')[3]

    const getQRCode = async () => {
        setIsFetching(true)
        try {
            const { data } = await fetchAxios.get(`payment/checkPayment/${pathname}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setTokenAsaas(data.payment.id_asaas_charge)
            setQrCode(data.qrCode)
            setInlineCode(data.payload)
            validTimePixSumTwoHours(data.payment.created_at)
            setTypePayment('PIX')
            setLocalizator(data.localizator)
            setDate([dayjs(data.booking.arrival_date).toDate(), dayjs(data.booking.departure_date).toDate()])

            const arrivalDate = dayjs(data.booking.arrival_date).toDate();
            const departureDate = dayjs(data.booking.departure_date).toDate();
            const diff = subtractDates(arrivalDate, departureDate);

            const newPaymentData: DataSessionPaymentProps = {
                cleaningValue: Number(data.values.cleaning_value),
                midStayValue: Number(data.values.midstay_value),
                nightValue: Number(data.values.night_value),
                condominiumValue: Number(data.values.condominium),
                iptu: Number(data.values.iptu),
                pacoteYogha: Number(data.values.yogha_pack),
                reserveDetails: {
                    mainImage: 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/1280x1080/filters:quality(65)/' + data.accommodation.featured_image,
                    accommodationName: data.accommodation.name_ad,
                    address: {
                        city: data.accommodation.city,
                        area: data.accommodation.area,
                        stateCode: "PR",
                        street: data.accommodation.street,
                    },
                    id: data.accommodation.id,
                    totalGuestReserve: data.booking.total_guests,
                },
                weekValue: diff > 26 ? Math.ceil(diff / 7) + 1 : Math.ceil(diff / 7),
                difference: diff,
                total: Number(data.payment.value_total),
                discount: false,
                pixCode: true,
                totalValueUndiscounted: Number(data.values.total_without_discount),
            };
            setNewPaymentData(newPaymentData)
            if (data?.cupom?.percentage) {
                saveToSessionStorage('hasCodePix', 'pixWithCupom')
                saveDiscount(data.cupom.percentage, true, data.cupom.id)
            } else {
                saveToSessionStorage('hasCodePix', 'recoverTotalValueUndiscounted')
            }
            localStorage.setItem('createdPix', data.payment.created_at);
        } catch (error) {
            console.error('Error fetching QRCode:', error)
        } finally {
            setIsFetching(false)
        }

    }

    useEffect(() => {
        return () => {
            removeFromSessionStorage('hasCodePix')
        }
    }, [])


    useEffect(() => {
        let intervalId: any = null;

        const fetchQRCodeAndVerifyPayment = async () => {
            if (qrCode === '') {
                await getQRCode();
            }

            intervalId = setInterval(verifyPixPayment, 10 * 1000);
        };

        fetchQRCodeAndVerifyPayment();

        // Call validTimePixSumTwoHours initially to set the timer
        const createdPix = localStorage.getItem('createdPix');
        validTimePixSumTwoHours(createdPix!);

        // Update the timer every minute
        const timerId = setInterval(() => {
            validTimePixSumTwoHours(createdPix!);
        }, 60 * 1000);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
            if (timerId) {
                clearInterval(timerId);
            }

        };
    }, [qrCode]);

    const isShortStay = difference < 27 ? 'Shortstay' : 'Midstay';

    const verifyPixPayment = async () => {

        const objDatalayer = {
            'event': 'purchase',
            'building': paymentData && paymentData.reserveDetails.building_name, //Building name//
            'city': paymentData && paymentData.reserveDetails.address.city, //Cidade do edifício//
            'conversion_value': paymentData && paymentData.total, //Valor total da reserva//
            'transaction_ID': localizator, //Identificação da reserva//
            'currency': 'BRL', //Inicialmente, traremos apenas a currency 'BRL'//
            'product_ID': paymentData && paymentData.reserveDetails.id, //Identificação da unidade//
            'product_name': paymentData && paymentData.reserveDetails.accommodationName, //Nome do imóvel//
            'client_ID': userCustomerId, //Identificação do cliente na tabela customer//
            'booking_type': isShortStay,
            'guest_number': paymentData && paymentData.reserveDetails.totalGuestReserve, //Número de hóspedes//
            'payment_type': 'PIX'
        }

        try {
            const { data } = await fetchAxios.post('payment/check', {
                tokenAssas: tokenAsaas,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (data.status === 'RESERVED') {
                if ((window as any).dataLayer) {
                    (window as any).dataLayer.push(objDatalayer)
                }
                navigate(`/payment/${tokenAsaas}/confirmado`)
            } else if (data.status === 'CANCELLED') {
                navigate(`/payment/${tokenAsaas}/erro-de-pagamento`)
            }
        } catch (erro) {
            console.error('Falha ao buscar o status do pagamento:', erro);
        }
    };

    const handleCopyInlineCode = async () => {
        try {
            await navigator.clipboard.writeText(inlineCode);
            setCopy("Copiado");
            setTimeout(() => {
                setCopy("Copiar");
            }, 2500);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const pixIcon = require('assets/svg/payment/PixIcon.svg').default

    return {
        qrCode,
        inlineCode,
        copy,
        validTimePix,
        handleCopyInlineCode,
        pixIcon,
        isFetching,
    }
}