import styled from "styled-components";

export const Container = styled.div`
    display: flex;

    position: absolute;

    inset: 0px;
    justify-content: center;
    align-items: center;

    height: 100vh;
    width: 100vw;

    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    z-index: 3000;

`;
