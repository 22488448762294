import { usePaymentProvider } from 'context/paymentContext';
import * as S from './styles'
import { PaymentTypeState } from 'pages/Payment';
import fetchAxios, { fetchAxiosPayment } from 'services/http';
import { useUserProvider } from 'hooks/userContext';
import useAlert from 'components/Notification';
import { ProductCard } from 'components/ProductCard';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'components/Button';
import { AxiosError } from 'axios';
import { IMaskInput } from 'react-imask';
import { NewInput } from 'components/NewInput';
import { useSessionStorage } from 'context/sessionStorage';
import { Icon } from 'assets/svg/Icon';
import { useScreenWidth } from 'utils/useScreenWidth';
import { Warning } from 'assets/svg/Warning';
import { useNavigate } from 'react-router-dom';
import { handleLogout } from 'components/User';

type Payment = {
    type: string;
    paymentType: PaymentTypeState;
    setPaymentType: (paymentType: PaymentTypeState) => void;
    token: string;
    brand: string;
    cardId: string;
    lastNumber: string;
    verify: boolean;
    onClick?: any;
    setReload: (open: boolean) => void
    loadingMessage?: (message: string) => void
    hasCupom?: boolean
    isBlock: boolean
    setOpenModalValidation: (open: boolean) => void
    setIdCardValidate: (id: string) => void
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    token: string;
    idCardValidate: string;
    setReload: (open: boolean) => void
    loadingMessage?: (message: string) => void
    setOpenLogoutModal: (open: boolean) => void
}

export const ModalReturnValidation = ({ open, setOpen, token, idCardValidate, setReload, loadingMessage, setOpenLogoutModal, }: ModalProps) => {

    const [amountCharged, setAmountCharged] = useState<string>('')
    const [openModalSecurity, setOpenModalSecurity] = useState<boolean>(false)
    const [timerTryAgain, setTimerTryAgain] = useState<number>(0)
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (timerTryAgain > 0) {
            setIsButtonDisabled(true);
            interval = setInterval(() => {
                setTimerTryAgain((prev) => prev - 1);
            }, 1000);
        } else {
            setIsButtonDisabled(false);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [timerTryAgain]);

    useEffect(() => {
        if (loadingMessage)
            loadingMessage('Aguarde enquanto carregamos as suas informações.')

        return () => {
            if (loadingMessage)
                loadingMessage('')
        }
    }, [])

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    const AlertMemo = useMemo(() => <Alert />, [Alert])

    const closeIcon = require('assets/svg/payment/X.svg').default

    const warningIcon = require('assets/svg/WarningCircle.svg').default

    const { creditCardToken } = usePaymentProvider()

    const handleConfirmCard = async () => {
        try {
            const formDataValidate = new FormData();
            formDataValidate.append("value", amountCharged.replace(',', '.'));
            formDataValidate.append("chargeId", idCardValidate);
            formDataValidate.append("token", String(token));
            formDataValidate.append("creditCardToken", creditCardToken);
            const res = await fetchAxiosPayment.post('/card-confirm', formDataValidate, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
            showAlert({ active: true, title: 'Cartão validado com sucesso!', type: "SUCCESS" })
            setTimeout(() => {
                setOpen(false)
                setReload(true)
            }, 2700);
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Valor errado!") {
                setTimerTryAgain(15)
                showAlert({ active: true, title: 'Oops! Valor inválido, tente novamente!', type: "ERROR" })
            } else if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && (axiosError.response.data.message === 'Bloqueado' || axiosError.response.data.message === "Cartão bloqueado")) {
                showAlert({ active: true, title: 'Não foi possível validar o cartão, entre em contato com o suporte para mais informações!', type: "ERROR" })
                setReload(true)
                setTimeout(() => {
                    setOpen(false)
                }, 3000);
            } else if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Usuario bloqueado no sistema!") {
                setOpenLogoutModal(true)
                setOpen(false)
                handleLogout()
            }
        }

    }

    return (<>
        {open ? <S.BackDrop>
            <S.ModalCard>
                <S.ContentModal>
                    <S.Header>
                        <S.Stages>
                            <S.StageArea><p>Infomações do cartão</p></S.StageArea>
                        </S.Stages>
                    </S.Header>
                    <div>
                        <S.CollumnModalValidateValue>
                            <S.HeaderValue>
                                <p>Uma cobrança foi realizada. Verifique a sua fatura ou extrato do cartão e Informe abaixo o valor exato da cobrança.</p>
                                <div style={{ display: 'flex', gap: 8 }}>
                                    <Warning color='#ED374C' size={16} /> <p style={{ fontSize: 14, color: '#ED374C', marginTop: -2 }}>Caso sejam feitas 3 tentativas de confirmação o cartão será invalidado no site, podendo entrar em contato com o suporte para mais informações.</p>
                                </div>
                            </S.HeaderValue>

                            <S.ColumnValue>
                                <NewInput mask={''} name='value' placeholder='' type='text' htmlFor='value'
                                    title='Valor cobrado:' width={"332px"}>
                                    <IMaskInput mask={'*,**'} type="text" placeholder='0,00' title='Valor cobrado:' onChange={(e) => setAmountCharged(e.currentTarget.value)} value={amountCharged} />
                                </NewInput>

                                <S.WarningRow onClick={() => setOpenModalSecurity(true)}>
                                    <img src={warningIcon} alt="" /><p>Porque preciso fazer isso?</p>
                                </S.WarningRow>
                            </S.ColumnValue>
                        </S.CollumnModalValidateValue>
                    </div>
                </S.ContentModal>

                <S.ModalFooter>
                    <Button onClickButton={() => setOpen(false)} size='medium' title='Cancelar' type='secondary' />
                    <Button
                        widthSize={'120px'}
                        onClickButton={handleConfirmCard}
                        size='medium'
                        title={isButtonDisabled ? `Aguarde ${timerTryAgain}s` : 'Próximo'}
                        type='primary'
                        disabled={isButtonDisabled}
                    />
                </S.ModalFooter>
                <S.CloseButton onClick={() => setOpen(false)}>
                    <img src={closeIcon} alt="" />
                </S.CloseButton>


            </S.ModalCard>
            <ProductCard width='297' bottomLine isOpen={openModalSecurity} setIsOpen={setOpenModalSecurity} transparent title='Segurança'>
                <S.SecurityContent>
                    <S.SecurityText>
                        <p>Para proteger sua conta, realizamos essa cobrança como medida antifraude, para confirmar a titularidade do cartão. Não se preocupe, esse valor será estornado.</p>
                    </S.SecurityText>
                    <Button widthSize={'297px'} onClickButton={() => setOpenModalSecurity(false)} size='large' title='Tudo bem, eu entendi' type='primary' />
                </S.SecurityContent>
            </ProductCard>
            {AlertMemo}
        </S.BackDrop> : null}
    </>
    )
}

export const Radio = ({ type, paymentType, setPaymentType, brand, token, cardId, lastNumber, verify = false, onClick, hasCupom, isBlock, setOpenModalValidation, setIdCardValidate }: Payment) => {

    const navigate = useNavigate()

    const { setCreditCardToken, setCreditCard } = usePaymentProvider()
    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const { customer, token: userToken } = useUserProvider()

    const { setIsLoading } = usePaymentProvider()

    const breakpoint = useScreenWidth()

    const { saveToSessionStorage, } = useSessionStorage()

    const handlePaymentType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentType({ type: event.target.value, cardId: cardId });
        setCreditCardToken(token);
        setCreditCard({
            token: token,
            brand: brand,
            lastNumbers: lastNumber,
            cardId: cardId,
            validated: verify
        });
        saveToSessionStorage('cardsPayment', {
            token: token,
            brand: brand,
            lastNumbers: lastNumber,
            cardId: cardId,
            validated: verify
        })

    };

    const getConfirmCard = async () => {
        setIsLoading(true)
        try {
            const res = await fetchAxios.get(`payment/checkCard/${cardId}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    },
                }
            )
            if (res.data.status === false) {
                setOpenModalValidation(true)
                setIdCardValidate(res.data.payment)
                setIsLoading(false)
                setCreditCardToken(token);
                try {
                    const formDataValidate = new FormData();
                    formDataValidate.append("creditCardToken", token);
                    formDataValidate.append("customer", customer);
                    formDataValidate.append("token", userToken);
                    const res = await fetchAxiosPayment.post('/payment-card-confirm', formDataValidate, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    })
                    setOpenModalValidation(true)
                    setIdCardValidate(res.data.chargeId)
                    setIsLoading(false)
                } catch (error) {
                    const axiosError = error as AxiosError;
                    if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === 'Transação não autorizada. Verifique os dados do cartão de crédito e tente novamente.') {
                        showAlert({ active: true, title: 'Transação não autorizada. Verifique os dados do cartão de crédito e tente novamente.', type: "ERROR" })
                    }
                    setIsLoading(false)
                }

            } else {
                setOpenModalValidation(true)
                setIdCardValidate(res.data.payment)
                setIsLoading(false)
            }

        } catch (error) {
        }
    }

    const getFlagCard = (brand: string) => {
        switch (brand) {
            case 'VISA':
                return require('assets/svg/flag/visa.svg').default
            case 'MASTERCARD':
                return require('assets/svg/flag/mastercard.svg').default
            case 'HIPERCARD':
                return require('assets/svg/flag/hipercard.svg').default
            case 'JCB':
                return require('assets/svg/flag/jcb.svg').default
            case 'BANESCARD':
                return require('assets/svg/flag/banescard.svg').default
            case 'AMEX':
                return require('assets/svg/flag/amex.svg').default
            case 'DISCOVER':
                return require('assets/svg/flag/discover.svg').default
            case 'ELO':
                return require('assets/svg/flag/elo.svg').default
            case 'CABAL':
                return require('assets/svg/flag/cabal.svg').default
            case 'PIX':
                return require('assets/svg/payment/PixIcon.svg').default
        }
    }
    const getBrandCard = (brand: string) => {
        switch (brand) {
            case 'VISA':
                return 'Visa'
            case 'MASTERCARD':
                return 'Mastercard'
            case 'HIPERCARD':
                return 'Hipercard'
            case 'JCB':
                return 'JCB'
            case 'BANESCARD':
                return 'Banescard'
            case 'AMEX':
                return 'Amex'
            case 'DISCOVER':
                return 'Discover'
            case 'ELO':
                return 'Elo'
            case 'CABAL':
                return 'Cabal'
            case 'PIX':
                return 'PIX'
        }
    }
    const deleteCard = async () => {
        try {
            const { data } = await fetchAxios.put(`/payment/cards/${lastNumber}`, {
                status: 'CANCELLED',
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                },
            }
            )
            onClick();

            if (data.code === 200) {
                showAlert({ active: true, title: 'Cartão excluído com sucesso ', type: 'SUCCESS' })
            }
            if (data.code === 400) {
                showAlert({ active: true, title: 'Erro ao excluir cartão', type: 'ERROR' })
            }
            setOpenDeleteModal(false)
        } catch (error) {
            showAlert({ active: true, title: 'Erro ao excluir cartão', type: 'ERROR' })
        }
    }

    return (
        <S.Container verify={!verify} >
            <S.InputColumn>
                <input type='radio' value={type} checked={type === paymentType.type && cardId === paymentType.cardId} onChange={handlePaymentType} />
                <S.Collumn className={getFlagCard(brand)}  >
                    <img src={getFlagCard(brand)} alt="" />
                    <S.ColumnLabel>
                        <label style={{ marginBottom: -8, marginTop: -8 }} htmlFor=""> {getBrandCard(brand)} {lastNumber !== '' ? `**** ${lastNumber}` : null} </label>
                        {isBlock === false && brand !== "PIX" && !verify ? <p>Não verificado</p> : null}
                        {isBlock === true && brand !== "PIX" ? <p>Cartão inválido</p> : null}
                    </S.ColumnLabel>
                </S.Collumn>
            </S.InputColumn>
            <S.CollumnButtons  >
                {isBlock === false && brand !== "PIX" ? <S.Button onClick={() => setOpenDeleteModal(true)}>
                    Excluir
                </S.Button> : null}
                {isBlock === false && !verify ? <S.Button className='confirm' onClick={getConfirmCard}>
                    Continuar verificação
                </S.Button> : null}
                {isBlock === true && brand !== "PIX" ? <S.ButtonLink className='confirm' href='https://s.tintim.app/whatsapp/6ba22fd0-7cd9-4e53-93bc-b1d9d70c98a2/56c213c6-41ad-4abe-9e55-fdf22208ac62'>
                    Entrar em contato com o suporte
                </S.ButtonLink> : null}
                {brand === "PIX" && !hasCupom && (
                    <S.PixDiscount>
                        {breakpoint ? null : <Icon.Warning />}
                        <p>5% de desconto</p>
                    </S.PixDiscount>
                )}
            </S.CollumnButtons>
            <Alert />
            <ProductCard key={token} bottomLine width='290' title='Excluir cartão' isOpen={openDeleteModal} setIsOpen={setOpenDeleteModal}>
                <S.TextHeading>Tem certeza que deseja excluir este cartão?</S.TextHeading>
                <S.ButtonContainer>
                    <Button size='medium' title='Cancelar' type='secondary' onClickButton={() => setOpenDeleteModal(false)} />
                    <Button size='medium' title='Sim, excluir' type='confirm' onClickButton={deleteCard} />
                </S.ButtonContainer>
            </ProductCard>
        </S.Container>
    )
}