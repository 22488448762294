import dayjs, { Dayjs } from 'dayjs';  // Importando dayjs
import { useEffect, useState } from 'react';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

// Atualize a assinatura da função
export const subtractDates = (date1: string | Date, date2: string | Date): number => {
  if (!date1 || !date2) {
    return 0;
  }
  // Fazendo o parse das datas no formato 'dd-mm-yyyy' se forem strings
  const parsedDate1 = typeof date1 === 'string' ? dayjs(date1, 'DD-MM-YYYY') : dayjs(date1);
  const parsedDate2 = typeof date2 === 'string' ? dayjs(date2, 'DD-MM-YYYY') : dayjs(date2);

  // Calculando a diferença em dias
  return parsedDate2.diff(parsedDate1, 'day');
};

export const useDateDifference = (dates: null | string[] | Date[]): { dayDifference: number, weeklyDifference: number } => {
  const [dayDifference, setDayDifference] = useState(0);
  const [weeklyDifference, setWeeklyDifference] = useState(0);

  useEffect(() => {

    if (!dates || dates.length < 2) {
 setDayDifference(0);
      setWeeklyDifference(0);
    } else {
      const diff = subtractDates(dates[0], dates[1]);

      setDayDifference(diff);
      setWeeklyDifference(() => {
        const midStay = diff > 26 && diff < 29 ? Math.ceil(diff / 7) + 1 : Math.ceil(diff / 7);
        return midStay;
      });
    }
  }, [dates]);

  return { dayDifference, weeklyDifference };
};
