import { StateCreator, create } from 'zustand';
import { persist, PersistOptions, PersistStorage } from 'zustand/middleware';
import { ReserveDetails } from './types';

export interface DataSessionPaymentProps {
    reserveDetails: ReserveDetails;
    weekValue: number,
    difference: number,
    nightValue: number,
    total: number,
    discount: boolean,
    condominiumValue: number,
    midStayValue: number,
    iptu: number,
    cleaningValue: number,
    pacoteYogha: number,
    pixCode?: boolean,
    totalValueUndiscounted?: number,
}

const initialState: DataSessionPaymentProps = {
    reserveDetails: {
        id: '',
        accommodationName: '',
        mainImage: '',
        address: {
            street: '',
            city: '',
            stateCode: '',
            area: '',
        },
    },
    totalValueUndiscounted: 0,
    weekValue: 0,
    difference: 0,
    nightValue: 0,
    total: 0,
    discount: false,
    condominiumValue: 0,
    midStayValue: 0,
    iptu: 0,
    cleaningValue: 0,
    pacoteYogha: 0,
    pixCode: false,
};

interface DataPaymentState {
    paymentData: DataSessionPaymentProps;
    setPaymentData: (data: DataSessionPaymentProps) => void;
    clearPaymentData: () => void;
    pixDiscountedTotalValue: (typePayment: string, pixCode?: boolean) => number;
    pixDiscountedNightValue: (discountNight: DiscountedNightValue) => number;
    pixDiscountedNightValueMidStay: (discountNight: DiscountedNightValue) => number;
    pixDiscountedTotalValueMidStay: (typePayment: string) => number;
    recoverNightByPixCode: () => number;
    recoverTotalValueByPixCode: () => number;
    recoverNightMidStayByPixCode: () => number;
    recoverTotalValueMidStayByPixCode: () => number;
    recoverTotalValueWithCupom: (percent: number) => number;
    recoverTotalValueMidStayWithCupom: (percent: number) => number;
}

// Função para converter sessionStorage em PersistStorage
const sessionStoragePersist: PersistStorage<DataPaymentState> = {
    getItem: (name) => {
        const value = sessionStorage.getItem(name);
        return value ? JSON.parse(value) : null;
    },
    setItem: (name, value) => {
        sessionStorage.setItem(name, JSON.stringify(value));
    },
    removeItem: (name) => {
        sessionStorage.removeItem(name);
    },
};

type SessionStoragePersist = (
    config: StateCreator<DataPaymentState>,
    options: PersistOptions<DataPaymentState>
) => StateCreator<DataPaymentState>;

type DiscountedNightValue = {
    typePayment: string;
    hasCupom: boolean;
    cupomValue: number;
}

const usePaymentStore = create<DataPaymentState>(
    (persist as SessionStoragePersist)(
        (set, get) => ({
            paymentData: initialState,
            setPaymentData: (data: DataSessionPaymentProps) => set({ paymentData: data }),
            clearPaymentData: () => set({ paymentData: initialState }),
            recoverTotalValueMidStayByPixCode: () => {
                const data = get().paymentData ?? initialState;
                return data.total / 0.95;
            },
            recoverNightMidStayByPixCode: () => {
                const data = get().paymentData ?? initialState;
                const sumAllValues = data.total - (data.cleaningValue + data.condominiumValue + data.iptu + data.pacoteYogha);
                return sumAllValues;
            },
            recoverTotalValueByPixCode: () => {
                const data = get().paymentData ?? initialState;
                if (data.totalValueUndiscounted) {
                    return data.totalValueUndiscounted;
                }
                return data.total;
            },
            recoverNightByPixCode: () => {
                const data = get().paymentData ?? initialState;
                const nightValue = data.total - data.cleaningValue;
                return nightValue;
            },
            recoverTotalValueWithCupom: (percent: number) => {
                const data = get().paymentData ?? initialState;
                return data.total / ((100 - percent) / 100);
            },
            recoverTotalValueMidStayWithCupom: (percent: number) => {
                const data = get().paymentData ?? initialState;
                const sumAccommodationValues = data.midStayValue + data.condominiumValue + data.iptu + data.pacoteYogha;
                const sumAllValues = data.midStayValue + data.condominiumValue + data.iptu + data.cleaningValue + data.pacoteYogha;
                const discountTotal = sumAllValues * (percent / 100);
                const total = (sumAccommodationValues - discountTotal) + data.cleaningValue;
                return total;
            },
            pixDiscountedNightValue: (discountNight: DiscountedNightValue) => {
                const data = get().paymentData ?? initialState;
                if (discountNight.hasCupom && discountNight.cupomValue > 0) {
                    const discountByTotal = data.total * (discountNight.cupomValue / 100);
                    const newTotalValue = data.nightValue - discountByTotal;
                    return newTotalValue;
                }
                else if (discountNight.typePayment === 'PIX' && !discountNight.hasCupom) {
                    const discountTotal = data.total * 0.05;
                    const newNightValue = data.nightValue - discountTotal;
                    return newNightValue;
                } else {
                    return data.nightValue;
                }
            },
            pixDiscountedNightValueMidStay: (discountNight: DiscountedNightValue) => {
                const data = get().paymentData ?? initialState;
                if (discountNight.hasCupom && discountNight.cupomValue > 0) {
                    const sumAllValues = data.midStayValue + data.condominiumValue + data.iptu + data.cleaningValue + data.pacoteYogha;
                    const discountTotal = sumAllValues * (discountNight.cupomValue / 100);
                    const newValueMidStay = data.midStayValue - discountTotal;
                    return newValueMidStay;
                }
                if (discountNight.typePayment === 'PIX' && !discountNight.hasCupom) {
                    const sumAllValues = data.midStayValue + data.condominiumValue + data.iptu + data.cleaningValue + data.pacoteYogha;
                    const discountTotal = sumAllValues * 0.05;
                    const newValueMidStay = data.midStayValue - discountTotal;
                    return newValueMidStay;
                } else {
                    return data.midStayValue;
                }
            },
            pixDiscountedTotalValue: (typePayment: string, pixCode?: boolean) => {
                const data = get().paymentData ?? initialState;
                if (pixCode) {
                    return data.total;
                }
                if (typePayment === 'PIX') {
                    const discountByTotal = data.total * 0.05;
                    const newTotalValue = (data.nightValue - discountByTotal) + data.cleaningValue;
                    return newTotalValue;
                }
                else {
                    return data.total;
                }
            },
            pixDiscountedTotalValueMidStay: (typePayment: string) => {
                const data = get().paymentData ?? initialState;
                if (typePayment === 'PIX') {
                    const sumAccommodationValues = data.midStayValue + data.condominiumValue + data.iptu + data.pacoteYogha;
                    const sumAllValues = data.midStayValue + data.condominiumValue + data.iptu + data.cleaningValue + data.pacoteYogha;
                    const discountTotal = sumAllValues * 0.05;
                    const total = (sumAccommodationValues - discountTotal) + data.cleaningValue;
                    return total;
                } else {
                    return data.total;
                }
            },
        }),
        {
            name: 'payment-storage',
            storage: sessionStoragePersist,
        }
    )
);

export default usePaymentStore;
