
import { Card } from "../../../../components/Card";
import { Navegation } from "../../../../components/Navegation"
import * as S from "./styles"
import fetchAxios from "services/http";
import { useUserProvider } from "hooks/userContext";
import useAlert from "components/Notification";
import { Button } from "components/Button";
import { useSpaProvider } from "hooks/spaRouterContext";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import { formatDate } from "utils/dateReserveFormatter";
import { useMobileNavigation } from "components/MobileNavigation/useMobileNavigation";
import { useEffect, useRef, useState } from "react";
export type ReservesProps = {
  id: string | number,
  accommodation: string,
  address: {
    street: string,
    number: string | number,
    area: string,
    town: string,
    state: string,
  },
  initialDate: string,
  finalDate: string,
  type: "UNPAID" | "RECEIVED" | "CANCELLED" | "CONFIRMADA" | "PAID",
  paymentType: string,
  image: string,
  guests: string | number,
  totalValue: string | number,
  parcelValue: string | number,
  totalParcells: string | number,
  title: string,
  streetNumbe: string,
  promotional?: boolean,
  ref_stays: string,
  idBuilding: number,
  dateEdit: string,
  checkinCode: string | null,
  statusCheckin: string,
  paymentId: string,
  createReserver: string,
  accommodationId?: string
}

const fetchReservations = async (token: string): Promise<ReservesProps[]> => {
  const { data } = await fetchAxios.get('/reservers/listReserver', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const newReservationObject: ReservesProps[] = data.map((item: any) => {

    return {
      id: item.idBooking,
      address: {
        street: item.address,
        number: item.street_number,
        area: item.areaBuilding,
        town: item.town,
        state: 'PR'
      },
      accommodation: item.accommodation,
      initialDate: formatDate(item.arrival_date),
      finalDate: item.departure_date,
      type: item.booking_type === "PAID" ? "PAID" : item.booking_type === "UNPAID" ? "UNPAID" : item.booking_type === "CANCELLED" ? "CANCELLED" : item.statusPayment === "CONFIRMED" ? "PAID" : item.booking_type === "CONFIRMADA" ? "CONFIRMADA" : item.booking_type === "CANCELLED" ? "CANCELLED" : item.statusPayment,
      paymentType: item.type,
      image: 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/1280x1080/filters:quality(65)/' + item.featured_image,
      guests: item.adults_number,
      totalValue: item.asaas_installments_value_total !== null ? item.asaas_installments_value_total : item.value_total,
      parcelValue: item.asaas_installments_value_total !== null ? item.asaas_installments_value_total : 0,
      totalParcells: item.asaas_installments_total !== null ? item.asaas_installments_total : 0,
      title: item.name_ad,
      streetNumbe: item.street_number,
      ref_stays: item.accRefStays,
      idBuilding: item.building_yogha,
      dateEdit: item.dateReserver,
      checkinCode: item.token,
      statusCheckin: item.statusCheckin,
      paymentId: item.id_asaas_charge,
      createReserver: item.createReserver
    }
  })

  const filteredByIdBookingSortedNewReservationObject = newReservationObject.filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.id === item.id
    ))
  )


  return filteredByIdBookingSortedNewReservationObject;
};


export const Reservations = () => {

  document.title = "Minhas reservas | Yogha";

  const { token } = useUserProvider()
  const { setExpanded, setPageChosen } = useSpaProvider();
  const navigate = useNavigate();

  const [visibleNavigation, setVisibleNavigation] = useState(true);


  const loadingImage = require('assets/image/payment/Loading.png')

  const { showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

  const openHeader = () => {
    setTimeout(() => {
      setExpanded(false)
    }, 10);
    setTimeout(() => {
      setPageChosen(2)
    }, 50);
  }
  const redirectHome = () => {
    openHeader();
    navigate('/')
  }

  const { data: reservations, isLoading, isFetching, isError } = useQuery<ReservesProps[]>('reservations', () => fetchReservations(token), {
    onSuccess: () => {
      showAlert({ active: true, title: 'Cartão excluído com sucesso ', type: 'SUCCESS' });
    },
    onError: () => {
      showAlert({ active: true, title: 'Erro ao listar reservas', type: 'ERROR' });
    }
  });

  const { elementLoaded } = useMobileNavigation({ isLoadingOrIsFetching: false, setVisibleNavigation });

  const elementScrollable = useRef(null);

  useEffect(() => {
    if (elementScrollable.current) {
      elementLoaded(elementScrollable.current);  // Passa o elemento para elementLoaded
    }
  }, [isLoading, isFetching]);


  return (<>
    <Navegation actualPage="Minhas reservas" />
    {isLoading || isFetching ? (
      <S.ContainerCards >
        <S.ContainerLoading>
          <h3>
            Aguarde enquanto buscamos as suas reservas.
          </h3>
          <S.LoadingImage src={loadingImage} alt="Carregamento reservas" />
        </S.ContainerLoading>
      </S.ContainerCards>
    ) :
      reservations && reservations.length <= 0 ? (
        <S.DivDescription>
          <S.MyReservations>
            Minhas  reservas
          </S.MyReservations>
          <S.Description>
            Aqui você poderá administrar as acomodações que você adicionou às suas reservas. Para realizar uma reserva, clique no botão abaixo e encontre um Yogha que seja a sua cara!
          </S.Description>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 32,
            whiteSpace: 'nowrap'
          }}>
            <Button onClickButton={() => { redirectHome(); }} size="medium" title="Explorar novos lugares" type="primary" />
          </div>
        </S.DivDescription>
      ) : (
        <S.ContainerCards ref={elementScrollable}>
          <S.MyReservations >
            Minhas  reservas
          </S.MyReservations>
          <S.CollectionCards >
            {reservations && reservations.length >= 1 && reservations?.map((item) => {
              return (
                <Card.Reserve key={item.id} {...item} />
              )
            })}
          </S.CollectionCards>
        </S.ContainerCards>
      )}
  </>
  )
}